<template>
  <picture ref="root" class="flex justify-center">
    <source
      v-if="srcMobile"
      :srcset="srcsetImage"
      media="(max-width: 1023px)"
    />
    <img
      class="h-full w-full"
      :class="[cover ? 'object-cover' : 'object-contain', imgClasses]"
      :style="imgStyle"
      :src="srcImage"
      :alt="alt?.trim()"
      :data-e2e-image="dataE2eImage"
      @load="load"
      @error="error"
    />
  </picture>
</template>

<script setup lang="ts">
interface BaseLazyImage {
  src: string;
  alt?: string;
  srcMobile?: string;
  imgClasses?: string | any[] | object;
  srcPlaceholder?: string;
  intersectionOptions?: IntersectionObserverInit;
  cover?: boolean;
  imgStyle?: string;
  noLazy?: boolean;
  dataE2eImage?: string;
}

const props = withDefaults(defineProps<BaseLazyImage>(), {
  srcMobile: "",
  alt: "",
  imgClasses: "",
  srcPlaceholder:
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
  intersectionOptions: () => ({ rootMargin: "100px" }),
  imgStyle: "",
  noLazy: false,
});

const { isCrawler } = useDevice();

const emit = defineEmits(["load", "error", "intersect"]);

const root = ref<HTMLElement | null>(null);
const state = reactive<{
  observer: IntersectionObserver | null;
  intersected: boolean;
  loaded: boolean;
}>({
  observer: null,
  intersected: false,
  loaded: false,
});

const srcImage = computed(() => {
  if (props.noLazy || isCrawler) {
    return props.src ? props.src : props.srcPlaceholder;
  }
  return state.intersected && props.src ? props.src : props.srcPlaceholder;
});
const srcsetImage = computed(() => {
  if (props.noLazy || isCrawler) {
    return props.srcMobile ? props.srcMobile : props.srcPlaceholder;
  }
  return state.intersected && props.srcMobile
    ? props.srcMobile
    : props.srcPlaceholder;
});

function load() {
  if (root.value && root.value.getAttribute("src") !== props.srcPlaceholder) {
    state.loaded = true;
    emit("load", root.value);
  }
}

function error() {
  return emit("error", root.value);
}

onMounted(async () => {
  await nextTick();
  setTimeout(async () => {
    if ("IntersectionObserver" in window) {
      state.observer = new IntersectionObserver((entries) => {
        const image = entries[0];
        if (image.isIntersecting) {
          state.intersected = true;
          state.observer?.disconnect();
          emit("intersect");
        }
      }, props.intersectionOptions);

      if (root.value) {
        await nextTick();
        state.observer.observe(root.value);
      }
    }
  }, 0);
});

onBeforeUnmount(() => {
  if ("IntersectionObserver" in window && state.observer) {
    state.observer.disconnect();
  }
});
</script>
